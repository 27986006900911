import {
  SectionV3WithSteps,
  ResolvedStep,
  DateFrame,
  ParticipantSection,
} from '../types/v3Types';
import type { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { IParticipantSectionsContext } from '../contexts/ParticipantSections/ParticipantSectionsContext';
import { toParticipantStepV3 } from '../contexts/ParticipantStepsDataProvider/toParticipantSteps';
import { getStartDate } from './GetStartDate';
import { isFlexible } from './isFlexible';
import { isSelfPaced } from './isSelfPaced';
import { addDays } from 'date-fns';
import isAfter from 'date-fns/isAfter';

export function toParticipantSectionsV3({
  sections,
  resolvedSteps,
  challenge,
  dateFrame,
}: {
  sections: SectionV3WithSteps[];
  challenge: Challenge;
  dateFrame?: DateFrame;
  resolvedSteps: ResolvedStep[];
}): {
  sections: IParticipantSectionsContext['listParticipantSections'];
  steps: IParticipantSectionsContext['participantSteps']['steps'];
} {
  const resolvedById = new Map(
    resolvedSteps.map((step) => [step.programStepId, step]),
  );
  const initialDate = isFlexible(challenge)
    ? new Date(dateFrame.start)
    : getStartDate(challenge);
  const participantSections = sections
    .sort((a, b) => {
      return isSelfPaced(challenge)
        ? a.ordering - b.ordering
        : a.delayInDays - b.delayInDays;
    })
    .map((section) => {
      const steps = section.steps.map((step) => {
        const resolvedStep = resolvedById.get(step.id);
        return toParticipantStepV3({
          step,
          resolvedStep,
          dateFrame: !initialDate ? dateFrame : undefined,
          initialDate,
        });
      });
      const result: ParticipantSection = {
        id: section.id,
        source: section,
        // there is no need to show details for scheduled programs, of if the section is migrated
        withoutDetails:
          !isSelfPaced(challenge) || challenge.sectionToStepMigrated,
        steps,
        waitingDate: undefined,
      };

      if (isSelfPaced(challenge) && section.delayInDays) {
        const startDate = addDays(
          new Date(dateFrame.start),
          section.delayInDays,
        );
        if (isAfter(startDate, new Date())) {
          result.waitingDate = startDate as unknown as string;
        }
      }

      return result;
    });
  const participantSteps = participantSections.flatMap(
    (section) => section.steps,
  );
  return {
    sections: participantSections.filter((section) => {
      //filter out sections with no steps for scheduled programs;
      return section?.steps?.length || isSelfPaced(challenge);
    }),
    steps: participantSteps,
  };
}
