import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { getChallengeData } from '../storage-contexts/Challenge';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { isUserJoinedAlready } from '../User/helpers/userTypeHandlers';
import { isRequiredOwnerData } from './helpers';
import { requestSectionV3 } from '../storage-contexts/Challenge/api/requestChallengeSections';
import {
  getSectionDescription,
  setDescription,
} from '../../selectors/sections';
import { ParticipantSection } from '../../types/v3Types';

export async function requestParticipantSection(
  sectionId: string,
  sections: ParticipantSection[],
  flowAPI: ControllerFlowAPI,
): Promise<void> {
  // here we assume, that challenge, participant and sections already loaded
  const challengeData = await getChallengeData(flowAPI);
  const challenge = challengeData?.challenge || null;
  const participant = (await userProviderPropsMap(flowAPI))?.participant;
  // const sections = (await participantSectionsPropsMap(flowAPI))
  //   ?.listParticipantSections;
  const isJoinedParticipant = isUserJoinedAlready(
    participant?.transitions?.[0]?.state,
  );
  const isPreviewWithParticipant =
    flowAPI.environment.isPreview && isJoinedParticipant;
  const isOwnerData = isRequiredOwnerData(flowAPI) && !isPreviewWithParticipant;
  if (!challenge || !(isOwnerData || participant) || !sections) {
    console.error("Can't request participant section, not enough data.", {
      challenge,
      participant,
      sections,
      isOwnerData,
    });
    return;
  }

  const sectionToUpdate = sections.find((section) => {
    return section?.id === sectionId;
  });
  let description: any = null;

  if (!sectionToUpdate) {
    console.error("Can't find participant section for update.");
  }

  flowAPI.controllerConfig.setProps({
    requestParticipantSectionStatus: 'IN_PROGRESS',
  });

  const section = await requestSectionV3({ sectionId }, flowAPI);
  description = getSectionDescription(section);

  setDescription(sectionToUpdate.source, description);

  flowAPI.controllerConfig.setProps({
    requestParticipantSectionStatus: 'SUCCESS',
    listParticipantSections: sections,
  });
}
